import React, { Component } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Wrapper = styled.section`
    color: var(--colored-gray-200);
    text-align: center;

    .title {
        color: var(--colored-gray-300);
        font-size: 4.8rem;
        font-family: 'Raleway';
        font-weight: 100;
    }
`;

const Form = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: 2.5rem;
    margin: 0 auto;

    @media(min-width: 112.5em) {
        width: 90%;
    }

    .form-item {
        flex: 1 1 25%;
        font-family: raleway;
        font-weight: 400;
        letter-spacing: 1rem;
        position: relative;
        margin: 2.5rem 0;

        @media(max-width: 75em) {
            flex: 1 1 50%;
            font-size: 2.5rem;
            letter-spacing: .7rem;
            margin: 1.5rem 0;
        }

        @media(max-width: 37.5em) {
            font-size: 2rem;
            letter-spacing: .5rem;
            margin: 1rem 0;
        }

        a:link,
        a:active,
        a:visited {
            text-decoration: none;
            cursor: pointer;
            color: black;
        }

        a:hover {
            color: grey;
        }

        a:hover ~ svg {
            opacity: 1;
            visibility: visible;
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            color: grey;
        }
    
    }
    
    .label {
        font-weight: bold;
        text-transform: capitalize;
        letter-spacing: .2rem;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    margin-right: 1rem;

    display: ${({ hidden }) => hidden ? 'none' : 'inline-block'};
    visibility: ${({ hidden }) => hidden ? 'hidden' : 'visible'};
    opacity: ${({ hidden }) => hidden ? '0' : '1'};
    ${({ hidden }) => hidden ? 'margin-left: 1rem;' : ''};
    ${({ color }) => color ? `fill: ${color};`: ''}
    
    transition: all .3s;
`;

class Biography extends Component {

    render() {
        return (
            <Wrapper>
                <div className="title">About me...</div>
                <Form className="form">
                    <label className="form-item label">Name:</label>
                    <span className="form-item">Wiston</span>

                    <label className="form-item label">lastname: </label>
                    <span className="form-item">Coronell</span>

                    <label className="form-item label"><Icon icon={faGithub} />Github:</label>
                    <span className="form-item">
                        <a href="https://github.com/coronellw">coronellw</a>
                        <Icon icon={faLink} hidden />
                    </span>

                    <label className="form-item label"><Icon icon={faLinkedin} />LinkedIn:</label>
                    <span className="form-item">
                        <a href="https://linkedin.com/in/coronellw">coronellw</a>
                        <Icon color='blue' icon={faLink} hidden />
                    </span>

                    <label className="form-item label">Nationality:</label>
                    <span className="form-item">Colombian</span>

                    <label className="form-item label">Contact:</label>
                    <span className="form-item">
                        <a href="#contact">Here</a>
                        <Icon icon={faLink} hidden />
                    </span>
                </Form>
            </Wrapper>
        )
    }
}

export default Biography;
import React from 'react';
import styled from 'styled-components';
import { Grid, Chip } from '@material-ui/core';
import { ArrowForwardIos as CheckIcon } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudSunRain,
  faComments,
  faDragon,
  faFilm,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import bgImg from '../assets/coffee_beans.webp';
import bgImg2 from '../assets/coffee_beans.jpg';

const Wrapper = styled.section`
  display: block;
  background-image: 
    linear-gradient(hsla(210, 50%, 95%, .75), hsla(210, 50%, 95%, .75)),
    url(${bgImg}),
    url(${bgImg2});
  background-size: cover;
  
  .subtitle {
    font-size: 4.8rem;
    font-family: 'Raleway';
    font-weight: 100;
    color: var(--main-200);
    text-align: center;
  }

  .demo-pages {
    display: flex;
    padding: 0 2rem;
    justify-content: space-around;
    gap: 5rem;
    margin: 4rem 0 5rem 0;

    .project {
      border: 1px solid var(--secondary-500);
      border-radius: .5rem;
      background-color: var(--main-500);
      flex: 1 1 100%;
      padding: 2rem 3rem;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--colored-gray-200);
        &__title {
          font-size: 2.5rem;
          text-decoration: none;
          font-family: 'Nunito';
          font-weight: 200;
          &:active, &:visited, &:link {
            color: var(--colored-gray-200);
          }
          &:hover {
            color: var(--colored-gray-300);
          }
        }

        &__links {
          a {
            &:not(:last-child) {
              margin-right: .25rem;
            }
            &:active, &:visited, &:link {
              color: var(--colored-gray-200);
            }
            &:hover {
              color: var(--colored-gray-300);
            }
          }
        }
      }

      &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__icon {
        font-size: 5rem;
        margin: 2rem 0;
        color: var(--main-200);

        @media(max-width: 75em) {
          font-size: 8rem;
        }

        &:hover {
          color: var(--main-300);
          cursor: pointer;
        }
      }

      &__description {
        font-family: 'Nunito';
        font-weight: 400;
        font-style: italic;
        color: var(--colored-gray-200);
        margin-bottom: 2rem;
        padding: 0 1rem;
      }
    }
  }

  @media(max-width: 75em) {
    .demo-pages{
      flex-direction: column;
    }
    .head {
      &__links {
        font-size: 2rem;
      }
    }

    .project {
      &__description {
        font-size: 2.25rem;
      }

      &__chip {
        font-size: 1.5rem;
      }
    }
  }

  @media(max-width: 37.5em) {
    margin: 1rem 0;
  }
`;

const Projects = props => {
  return (
    <Wrapper>
      <div className="subtitle">Demo Projects</div>
      <div className="demo-pages">
        <div className="project">
          <div className="head">
            <a className="head__title" href="http://tmdb.coronellw.com" rel="noopener noreferrer" target="_blank" ><h3>Movies</h3></a>
            <span className="head__links">
              <a href="https://github.com/coronellw/tmdb" title="Source Code" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={faGithub} size="lg" />
              </a>
              <a href="http://tmdb.coronellw.com" rel="noopener noreferrer" target="_blank" title="Live Demo">
                <FontAwesomeIcon icon={faLink} size="lg" />
              </a>
            </span>
          </div>

          <div className="project__icon-container" onClick={() => { window.open('http://tmdb.coronellw.com', '_blank', 'noopener', 'noreferrer') }}>
            <FontAwesomeIcon className="project__icon" icon={faFilm} size="lg" />
          </div>
          <p className="project__description">Using tmdb API can diplay movies on theater and search through TMDB database</p>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Chip className="project__chip" icon={<CheckIcon />} label="ReactJS" color="primary" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" icon={<CheckIcon />} label="Redux" color="primary" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" icon={<CheckIcon />} label="Redux Thunk" color="primary" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" icon={<CheckIcon />} label="Axios" color="primary" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" icon={<CheckIcon />} label="Responsive" color="primary" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" icon={<CheckIcon />} label="REST" color="primary" />
            </Grid>
          </Grid>
        </div>

        <div className="project">
          <div className="head">
            <a className="head__title" href="http://chat.coronellw.com" rel="noopener noreferrer" target="_blank" ><h3>Chat App</h3></a>
            <span className="head__links">
              <a href="https://github.com/coronellw/chat-app" rel="noopener noreferrer" target="_blank" title="Source Code">
                <FontAwesomeIcon icon={faGithub} size="lg" />
              </a>
              <a href="http://chat.coronellw.com" rel="noopener noreferrer" target="_blank" title="Live Demo">
                <FontAwesomeIcon icon={faLink} size="lg" />
              </a>
            </span>
          </div>

          <div className="project__icon-container" onClick={() => { window.open('http://chat.coronellw.com', '_blank', 'noopener', 'noreferrer') }}>
            <FontAwesomeIcon className="project__icon" icon={faComments} size="lg" />
          </div>
          <p className="project__description">A simple chat app, handles urser lists by room.</p>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="NodeJS" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="SocketIO" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="Express" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="Real time" />
            </Grid>
          </Grid>
        </div>

        <div className="project">
          <div className="head">
            <a className="head__title" href="http://pokedex.coronellw.com" rel="noopener noreferrer" target="_blank" ><h3>Pokedex</h3></a>
            <span className="head__links">
              <a href="https://github.com/coronellw/pokedex" rel="noopener noreferrer" target="_blank" title="Source Code">
                <FontAwesomeIcon icon={faGithub} size="lg" />
              </a>
              <a href="http://pokedex.coronellw.com" rel="noopener noreferrer" target="_blank" title="Live Demo">
                <FontAwesomeIcon icon={faLink} size="lg" />
              </a>
            </span>
          </div>
          <div className="project__icon-container" onClick={() => { window.open('http://pokedex.coronellw.com', '_blank', 'noopener', 'noreferrer') }}>
            <FontAwesomeIcon className="project__icon" icon={faDragon} size="lg" />
          </div>
          <p className="project__description">Uses PokeAPI public API to show pokemon data</p>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="ReactJS" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="Axios" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="Responsive" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="REST" />
            </Grid>
          </Grid>
        </div>

        <div className="project">
          <div className="head">
            <a className="head__title" href="http://weather.coronellw.com" rel="noopener noreferrer" target="_blank" ><h3>Weather</h3></a>
            <span className="head__links">
              <a href="https://github.com/coronellw/node3-weather-website" rel="noopener noreferrer" target="_blank" title="Source Code">
                <FontAwesomeIcon icon={faGithub} size="lg" />
              </a>
              <a href="http://weather.coronellw.com" rel="noopener noreferrer" target="_blank" title="Live Demo">
                <FontAwesomeIcon icon={faLink} size="lg" />
              </a>
            </span>
          </div>

          <div className="project__icon-container" onClick={() => { window.open('http://weather.coronellw.com', '_blank', 'noopener', 'noreferrer') }}>
            <FontAwesomeIcon className="project__icon" icon={faCloudSunRain} size="lg" />
          </div>

          <p className="project__description">This Demo app provides the current weather based on location.</p>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="NodeJS" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="Express" />
            </Grid>
            <Grid item>
              <Chip className="project__chip" color="primary" icon={<CheckIcon />} label="Handlebars" />
            </Grid>
          </Grid>
        </div>
      </div>
    </Wrapper>
  )
}

export default Projects;
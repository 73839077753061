import React, { Component } from 'react';
import styled from 'styled-components';
import { Radar } from '@nivo/radar';
import { Bar } from '@nivo/bar';

const frontend = [
    { language: 'JS/ES6+', score: 90 },
    { language: 'API', score: 100 },
    { language: 'Testing', score: 75 },
    { language: 'HTML4/5', score: 92 },
    { language: 'CSS', score: 80 },
];

const backend = [
    { language: 'Java', score: 75 },
    { language: 'Python', score: 60 },
    { language: 'Ruby', score: 65 },
    { language: 'C#', score: 45 },
    { language: 'Node', score: 85 },
];

const additionalSkills = [
    {language: 'Networking', score: 90},
    {language: 'Git', score: 95},
    {language: 'Unix/Linux', score: 90},
    {language: 'SQL', score: 80},
    {language: 'AWS', score: 60},
];

const language = [
    {language: 'French', speaking: 70, listening: 80, writing: 50, overall: 66.6},
    {language: 'English', speaking: 95, listening: 95, writing: 90, overall: 93.3},
    {language: 'Spanish', speaking: 100, listening: 100, writing: 100, overall: 100},
];

const Wrapper = styled.section`
    text-align: center;
    background: var(--secondary-500);
    min-width: 25.625em;
    @media(max-width: 37.5em){
        border-radius: 0;
    }

    .subtitle {
        color: var(--main-200);
        font-size: 4.8rem;
        font-family: 'Raleway';
        font-weight: 100;
    }

    .skill__title {
        font-family: 'Nunito';
        font-weight: 400;
        font-size: 3rem;
        color: var(--colored-gray-300);
    }

    .skill__graphics {
        margin: 2rem auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

`;


const StyledRadar = styled(Radar)`
    fill: red !important;
`;

class Skills extends Component {
    render() {
        return (
            <Wrapper>
                <span className="subtitle">Skills</span>
                <div className="skill__graphics">
                    <div className="skill__graphic">
                        <h3 className="skill__title">Frontend skills</h3>
                        <StyledRadar
                            data={frontend}
                            keys={['score']}
                            indexBy='language'
                            width={300}
                            height={400}
                            margin={{ top: 50, right: 60, bottom: 0, left: 60 }}
                            maxValue={100}
                            fillOpacity={.25}
                            gridShape="linear"
                            colors={{scheme: 'dark2'}}
                            curve="linearClosed"
                            blendMode="normal"
                            isInteractive={true}
                        />
                    </div>
                    <div className="skill__graphic">
                        <h3 className="skill__title">Backend skills</h3>
                        <Radar
                            data={backend}
                            keys={['score']}
                            indexBy='language'
                            width={300}
                            height={400}
                            margin={{ top: 50, right: 60, bottom: 0, left: 60 }}
                            maxValue={100}
                            fillOpacity={.25}
                            gridShape="linear"
                            colors={{scheme: 'set1'}}
                            curve="linearClosed"
                            blendMode="normal"
                            isInteractive={true}
                        />
                    </div>
                    <div className="skill__graphic">
                        <h3 className="skill__title">Useful skills</h3>
                        <Radar
                            data={additionalSkills}
                            keys={['score']}
                            indexBy='language'
                            width={300}
                            height={400}
                            margin={{ top: 50, right: 60, bottom: 0, left: 60 }}
                            maxValue={100}
                            fillOpacity={.25}
                            gridShape="linear"
                            colors={{scheme: 'category10'}}
                            curve="linearClosed"
                            blendMode="normal"
                            isInteractive={true}
                        />
                    </div>
                    <div className="skill__graphic">
                        <h3 className="skill__title">Language</h3>
                        <Bar
                            data={language}
                            keys={['speaking','listening','writing','overall']}
                            indexBy="language"
                            width={300}
                            height={300}
                            layout="horizontal"
                            maxValue={100}
                            groupMode="grouped"
                            label={'value'}
                            colors={{scheme: 'spectral'}}
                            margin={{ top: 50, right: 20, bottom: 0, left: 60 }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 30,
                                    translateY: 50,
                                    itemsSpacing: 2,
                                    itemWidth: 75,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default Skills;

import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  display: grid;
  text-align: center;
  padding: 2rem 0;
  grid-gap: 1rem;
`;

const Text = styled.p`
  font-size: 2.6rem;
`;

const Footer = props => {
  return (<StyledFooter>
    <Text>This site is still under construction</Text>
  </StyledFooter>);
}

/**
 <div>  
      <h3>About</h3>
      <ul>
        <li>Location</li>
        <li>Goals</li>
        <li>This site</li>
        <li>Education</li>
      </ul>
    </div>

    <div>  
      <h3>Useful Links</h3>
      <ul>
        <li>LinkedIn</li>
        <li>Github</li>
        <li>Gitlab</li>
      </ul>
    </div>

    <div>  
      <h3>Contact</h3>
      <ul>
        <li>Email me</li>
        <li>View more</li>
      </ul>
    </div>
 */

export default Footer;
import React from 'react';
import { ThemeProvider } from '@material-ui/styles'

import theme from '../Theme';

import Projects from '../components/Projects';
import Header from '../components/Header';
import Introduction from '../components/Introduction';
import Biography from '../components/Biography';
import Footer from '../components/Footer';
import Skills from '../components/Skills';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Introduction />
      <Projects /> 
      <Skills />
      <Biography />
      <Footer />
    </ThemeProvider>
  );
}

export default App;

import { createMuiTheme } from '@material-ui/core/styles';

export const primary = "hsl(210, 100%, 25%)";
export const secondary = "hsl(25, 100%, 50%)";

export default createMuiTheme({
  palette: {
    common: {
      cyan: `${primary}`,
      brown: `${secondary}`
    },
    primary: {
      main: `${primary}`,
    },
    secondary: {
      main: `${secondary}`
    }
  },
  typography: {
    fontFamily: "'Nunito', 'Josefin Sans','Raleway', sans-serif"
  }
})
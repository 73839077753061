import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChessRook } from '@fortawesome/free-solid-svg-icons';
import bgImg from '../assets/colombia_ctg.webp';
import bgImg2 from '../assets/colombia_ctg.jpg';

const Wrapper = styled.section`
  text-align: center;
  border-radius: .5rem .5rem 0 0;
  padding: 0;
  
  .dev__name {
    font-size: 12rem;
    padding: 15rem 0;
    background-color: var(--colored-gray-100);
    background-image:  url(${bgImg}), url(${bgImg2});
    background-size: cover;
    background-position: center, right bottom;
    background-repeat: no-repeat no-repeat;
    h1 {
      background-color: hsla(210, 50%, 90%, .7);
      font-family: 'Raleway';
      font-weight: 100;
      font-style: italic;
      letter-spacing: .5rem;
    }
  }

  .dev__history {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--main-500);
    color: var(--main-300);
    padding: 4rem 0;

    .subtitle {
      font-size: 2.4rem;
      margin-bottom: 1rem;
      color: var(--main-200);
    }

    @media(max-width: 37.5em) {
      border-radius: 0;
    }

    .dev__role {
      font-style: italic;
      font-weight: 100;
      font-family: 'Josefin Sans';
      .emphasis {
        color: var(--primary-500);
        font-weight: 900;
        font-style: initial;
        font-family: 'Raleway';
      }
    }

    .dev__desc {
      display: flex;
      justify-content: center;
      color: var(--gray-300);
      width: 65ch;
      font-size: 1.5rem;
      text-align: initial;
    }
  }

  @media(max-width: 75em) {
    .dev__name {
      font-size: 10rem;
    }

    .dev__history {
      .dev__desc {
        width: 45ch;
        font-size: 2rem;
      }
    }
  }

  @media(max-width: 37.5em) {
    .dev__name{
      font-size: 5rem;
    }

    .dev__history {
      .dev__desc {
        width: 100%;
        padding: 0 2rem;
      }
    }
  }
`;

const IconContainer = styled.span`
  display: block;
  font-size: 8rem;
  margin: 2rem auto;
  color: var(--main-200);
`;

const Header = props => {
  return (
    <Wrapper>
      <div className="dev__name">
        <Typography variant="h1" color="primary">Wiston Coronell</Typography>
      </div>
      <div className="dev__history">
        <Typography variant="h2" color="primary" className="dev__role"><span className="emphasis">Web</span>Developer</Typography>
        <IconContainer>
          <FontAwesomeIcon icon={faChessRook} size="lg" />
        </IconContainer>
        <Typography className="subtitle">Making a web of realities with each project.</Typography>
        <Typography variant="body1" className="dev__desc">
          I'm a front-end web developer focused on ReactJS and proficient in the use of tools such as NodeJS, javascript, HTML, CSS,
          Databases Relational and non-relational who is always eager for knowledge and new challenges, a solid team member with leadership.
          <br />
          I'm also proficient in unit testing methodlogies such as Jest or Mocha.
          </Typography>
      </div>
    </Wrapper>
  );
}

export default Header;